<template>
  <div>
    <a-modal v-model="visible" width="768px" :confirmLoading="loading" :maskClosable="false" centered @cancel="cancel"
      @ok="confirm">
      <div slot="title">{{form.id ? `编辑分类 - ${form.name}` : '新增分类' }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4, md: 6 }"
          :wrapper-col="{ span: 16, md: 18 }">
          <a-row gutter="16">
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="分类名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
         
          <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { categoriesCreate, categoriesUpdate } from '@/api/categories';
  import rules from './rules.js'

  export default {
    props: ['visible', 'form'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        rules,
        loading: false,
      };
    },
    methods: {
      confirm() {
        if (this.form.inventory_upper != undefined && this.form.inventory_lower != undefined
          && this.form.inventory_upper < this.form.inventory_lower) {
          this.$message.error('库存下限不能大于库存上限');
          return
        }

        if (this.form.temperature_upper != undefined && this.form.temperature_lower != undefined
          && this.form.temperature_upper < this.form.temperature_lower) {
          this.$message.error('温度下限不能大于温度上限');
          return
        }

        if (this.form.humidity_upper != undefined && this.form.humidity_lower != undefined
          && this.form.humidity_upper < this.form.humidity_lower) {
          this.$message.error('湿度下限不能大于湿度上限');
          return
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true;
            let func = this.form.id ? categoriesUpdate : categoriesCreate;
            func(this.form).then(data => {
              this.$message.success(this.form.id ? '修改成功' : '新增成功');
              this.$emit(this.form.id ? 'update' : 'create', data);
              this.cancel();
            }).finally(() => {
              this.loading = false;
            });
          }
        });
      },
      cancel() {
        this.$emit('cancel', false);
        this.$refs.form.resetFields();
      },
    },
  }
</script>

<style scoped>
</style>